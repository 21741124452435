<template>
  <v-app>
    <nav>
      <app-header></app-header>
    </nav>
    <v-main class="mt-15">
      <v-container id="body">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Header from "@/components/Header/Header";

export default {
  name: 'App',
  components: {
    appHeader: Header,
  }
}
</script>

<style lang="scss">
@import '~vuetify/styles';
@import "@/assets/scss/main.scss";

#body {
  width: 66%;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 90%;
  }
}

.navigation {
  z-index: 1000;
}
</style>
