<template>
  <my-base></my-base>

  <div class="d-flex justify-center mt-5">
    <v-card class="w-100 color-my-secondary">
      <v-card-title class="bg-my-primary pa-2">
        <h1>{{ $t('header.contact') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-2">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-img
                  alt="Aldasforras Logo"
                  class="shrink mt-5"
                  contain
                  src="../assets/images/templom.jpg"
                  transition="scale-transition"
                  height="300"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="d-flex flex-column justify-sm-space-evenly">
              <p class="p_contact card-text mt-5"><b>{{ $t('contact.email') }} </b> aldasforras@gmail.com</p>
              <p class="p_contact card-text mt-5"><b>{{ $t('contact.seat') }} </b> 4254 Nyíradony, Tompa Mihály u. 1.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Base from "@/components/Base";

export default {
  title() {
    return `Aldasforras - Contact`
  },

  name: "Contact",
  components: {
    myBase: Base
  }
}
</script>

<style scoped lang="scss">
#p_contact {
  font-size: 1.0rem;
}
</style>
