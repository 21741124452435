export default {
  "header": {
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rólunk"])},
    "szja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szja 1%"])},
    "annual_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves beszámolók"])},
    "annual_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves beszámoló"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok"])}
  },
  "about_us": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Áldás Forrás Alapítvány keresztyén értékeket képvisel, melyet 2016-ban hoztunk létre attól a felismeréstől vezérelve, hogy segítsük a hazánkban és a környező országokban élő szociálisan rászoruló családokat, illetve, hogy támogassuk a fogyatékkal élő gyermekek családjainak anyagi és egyéb szükségleteinek kielégítését származástól, nemzetiségtől, vallástól és bőrszíntől függetlenül."])},
    "our_aims": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célunk:"])},
      "li_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szociálisan rászorultak részére személyes gondoskodást nyújtani"])},
      "li_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az egyenlő életesélyek megteremtése"])},
      "li_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["házi segítségnyújtás azon személyek részére, akik otthonukban önmaguk ellátására saját erőből nem képesek és róluk nem gondoskodnak"])},
      "li_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a határon túli kapcsolatok erősítése, illetve az ott élő szociálisan rászorultak támogatása"])},
      "li_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a peremhelyzetű családok és fogyatékkal élő emberek kiemelt segítése"])},
      "li_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szociálisan hátrányos helyzetű személyek és családok határon belüli üdülésének elősegítése, fizikai és mentális egészségük fejlődésének támogatása"])},
      "li_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szociálisan hátrányos helyzetű személyek és családok tagjai munkahelykeresésének segítése"])}
    },
    "we_do": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amit csinálunk:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közreműködünk az igénylő családi gondjainak rendezésében, életvitelét hátrányosan befolyásoló okok feltárásában, illetve megszűntetésében, mentális problémák megoldásában. Az igénylő kérelmére, problémáinak rendezése érdekében életvezetési és egyéb tanácsokat adunk, segítséget nyújtunk. Folyamatosan figyelemmel kísérjük a működési területünkön élő hátrányos helyzetű családok helyzetének alakulását. Oktatási, nevelési, mentálhigiénés, rekreációs céllal csoportos tevékenységet szervezünk, végzünk. A lakhatási problémákkal küszködő krízishelyzetbe került családok, családtöredékek részére szálláslehetőséget biztosítunk. Adományokat gyűjtünk, továbbítunk, illetve közvetítünk az arra rászorulók számára. A fogyatékkal élő emberek és hátrányos helyzetű családok tagjainak munka lehetőséget keresünk."])}
    }
  },
  "contact": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Székhely:"])}
  },
  "base": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Egymás terhét hordozzátok\" Gal 6.2"])}
  }
}