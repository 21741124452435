<template>
  <div class="d-flex justify-center mt-2">
    <v-card class="w-100 bg-my-primary">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-img
                  alt="Aldasforras Logo"
                  class="shrink ma-3"
                  contain
                  src="../assets/logo.png"
                  transition="scale-transition"
                  height="300"/>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8">
              <div class="d-flex flex-column align-center h-100 justify-space-around pa-5">
                <h1 class="color-my-secondary align-self-start">Áldás Forrás Alapítvány</h1>
                <h3 class="color-my-secondary align-self-end">{{ $t('base.quote') }}</h3>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Base"
}
</script>

<style scoped lang="scss">
@import '~vuetify/styles';

h1 {
  font-size: 4rem;
  line-height: initial;
  width: 100%;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 2rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: initial;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 1rem;
  }
}

v-img {
  height: 300px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 30% !important;
  }
}
</style>
