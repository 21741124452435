export default {
  "header": {
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "szja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szja 1%"])},
    "annual_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual reports"])},
    "annual_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual report"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "about_us": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blessing Source is a christian-related foundation established in 2016 to help socially unprivileged\n            families, support hadicapped children’s families both financially and in kind irrespective origin,\n            nationality, religio and flesh colour in Hungary and nearby countries."])},
    "our_aims": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our aims:"])},
      "li_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provide personal provision for socially unprivileged people"])},
      "li_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create equal life prospects"])},
      "li_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offer home-help for those who are unable to self-supporting and nobody cares them"])},
      "li_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strengthen over-border relations and support there the socially unprivileged people"])},
      "li_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["give extra help for families in deep poverty and handicapped people"])},
      "li_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organise internal holidays for socially unprivileged people and families, support them to imporve their physical and mental health"])},
      "li_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["help socially unprivileged people and their family members with finding workplace"])}
    },
    "we_do": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We assist the applicant to solve their family problems, find the source of the\n            disadvantageous causes affecting their life style, help to arrange them and give mental help, as well. If\n            if is required, we also give advice regarding life style organizing and other areas. We continuously\n            observ the situation of the unprivileged families at our field of activity. We organise team work in the\n            field of education, mental hygiene and recreation. We offer accomodation for familes and family fractions\n            in crisis fighting with residence problems. We collect and forward donation for people in need. We look\n            for work opportunities for handicapped people and unprivileged families."])}
    }
  },
  "contact": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat:"])}
  },
  "base": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Carry each other’s burdens\" Gal 6.2"])}
  }
}