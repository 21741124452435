import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        language: 'hu'
    },
    getters: {
        language: state => {
            return state.language;
        }
    },
    mutations: {
        initialaiseStore: (state => {
            if (localStorage.getItem('language')) {
                state.language = localStorage.getItem('language')
            }
        }),
        updateLanguage: (state, payload) => {
            state.language = payload;
        }
    }
});
