<template>
  <myBase></myBase>

  <div class="d-flex justify-center mt-5">
    <v-card class="w-100 color-my-secondary">
      <v-card-title class="bg-my-primary pa-2 ma-0">
        <h1>{{ $t('header.about_us') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-2">
          <p>{{ $t('about_us.description') }}</p>
          <h2 class="card-title mt-4">{{ $t('about_us.our_aims.title') }}</h2>
          <ul class="list-group list-group-flush mt-2">
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_1') }}</li>
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_2') }}</li>
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_3') }}</li>
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_4') }}</li>
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_5') }}</li>
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_6') }}</li>
            <li class="aboutuslist">{{ $t('about_us.our_aims.li_7') }}</li>
          </ul>

          <h2 class="card-title mt-4">{{ $t('about_us.we_do.title') }}</h2>
          <p class="card-text mt-2">{{ $t('about_us.we_do.description') }}</p>
        </v-container>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import Base from "@/components/Base";

export default {
  title() {
    return `Aldasforras - About us`
  },

  name: "AboutUs",
  components: {
    myBase: Base
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/styles';

.aboutuslist {
  list-style-type: circle;
  margin-left: 20px;
  margin-bottom: 5px;
}
</style>
