import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import {createRouter, createWebHistory} from "vue-router";
import {routes} from "@/routs";
import i18n from './i18n'
import store from "@/store/store";

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(store)
app.use(i18n);
app.use(vuetify);
app.use(router);

router.isReady().then(() => {
    app.mount('#app');
})
