<template>
  <my-base></my-base>

  <v-img
      alt="Aldasforras Logo"
      class="shrink mt-5"
      contain
      src="../assets/images/szja.jpg"
      transition="scale-transition"
      height="400"/>
</template>

<script>
import Base from "@/components/Base";

export default {
  title() {
    return `Aldasforras - Szja`
  },

  name: "Szja",
  components: {
    myBase: Base
  }
}
</script>

<style scoped>

</style>
