<template>
  <v-toolbar
      min-height="100px"
      dark
      class="toolbar"
  >
    <v-container class="toolbar-conatiner">
      <v-btn class="hidden-md-and-up" @click="toggleMenu = !toggleMenu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div class="custom-menu"
           :class="[isMobile ? 'custom-menu__desktop': 'custom-menu__mobile', toggleMenu?'custom-menu__mobile--active':'']">

        <router-link :to="{name: 'AboutUs'}" v-slot="{ href, route, navigate, isActive, isExactActive }"
                     @click="setToogleMenu">
          <v-btn class="menu-item" :active="isActive">
            <div class="menu-item__text">{{ $t('header.about_us') }}</div>
          </v-btn>
        </router-link>

        <router-link :to="{name: 'Szja'}" v-slot="{ href, route, navigate, isActive, isExactActive }"
                     @click="setToogleMenu">
          <v-btn class="menu-item" :active="isActive">
            <div class="menu-item__text">{{ $t('header.szja') }}</div>
          </v-btn>
        </router-link>

        <div class="dropdown">
          <v-btn class="menu-item" :active="isActive">
            <div class="menu-item__text">{{ $t('header.annual_reports') }}</div>
          </v-btn>

          <div class="dropdown-content">
            <v-btn class='menu-item' href="/documents/2021_eves-beszamolo.pdf" download>
              <div class="menu-item__text">2021 {{ $t('header.annual_report') }}</div>
            </v-btn>
            <v-btn class='menu-item' href="/documents/2020_eves-beszamolo.pdf" download>
              <div class="menu-item__text">2020 {{ $t('header.annual_report') }}</div>
            </v-btn>
            <v-btn class='menu-item' href="/documents/2019_eves-beszamolo.pdf" download>
              <div class="menu-item__text">2019 {{ $t('header.annual_report') }}</div>
            </v-btn>
            <v-btn class='menu-item' href="/documents/2018_eves-beszamolo.pdf" download>
              <div class="menu-item__text">2018 {{ $t('header.annual_report') }}</div>
            </v-btn>
            <v-btn class='menu-item' href="/documents/2017_eves-beszamolo.pdf" download>
              <div class="menu-item__text">2017 {{ $t('header.annual_report') }}</div>
            </v-btn>
          </div>
        </div>

        <router-link :to="{name: 'Contact'}" v-slot="{ href, route, navigate, isActive, isExactActive }"
                     @click="setToogleMenu">
          <v-btn class="menu-item" :active="isActive">
            <div class="menu-item__text">{{ $t('header.contact') }}</div>
          </v-btn>
        </router-link>

        <v-spacer></v-spacer>

        <v-btn text v-bind="attrs"
               v-on="on" class="menu-item" @click="setLanguage('en')">
          <div class="menu-item__icon" style="width: 20px; height: 20px;">
            <img :src="`/flags/en.svg`">
          </div>
        </v-btn>

        <v-btn text v-bind="attrs"
               v-on="on" class="menu-item" @click="setLanguage('hu')">
          <div class="menu-item__icon" style="width: 20px; height: 20px;">
            <img :src="`/flags/hu.svg`">
          </div>
        </v-btn>
      </div>
    </v-container>
  </v-toolbar>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      toggleMenu: false,
    }
  },
  created() {
    this.$i18n.locale = this.language;
  },
  components: {},
  methods: {
    ...mapMutations([
      'updateLanguage'
    ]),
    setToogleMenu() {
      if (this.$vuetify.display.smAndDown) {
        this.toggleMenu = !this.toggleMenu;
      }
    },
    setLanguage(value) {
      this.$i18n.locale = value;
      this.updateLanguage(value);

      localStorage.setItem('language', value);

      this.setToogleMenu();
    }
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    isMobile() {
      return this.$vuetify.display.mdAndUp
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/styles';
@import "@/assets/scss/main";

::v-deep .v-btn {
  height: auto !important;
}

::v-deep .v-btn__content {
  display: flex;
  flex-direction: column;
}

::v-deep .v-toolbar__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toolbar {
  position: fixed;
  width: 100%;
  background: $color-light-grey;
  z-index: 1000;
}

.toolbar-conatiner {
  width: 66%;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100% !important;
  }
}

.custom-menu {
  display: flex;
  z-index: 1000;

  &__desktop {
    flex-direction: row;
  }

  &__mobile {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    flex-direction: column;
    display: none;
    background-color: white;

    &--active {
      display: flex;
    }

    & button {
      width: 100%;
    }
  }

  & a {
    text-decoration: none;
    color: $color-black !important;
  }
}

.menu-item {
  padding: 10px 16px !important;
  //margin-right: 5px;
  color: $color-my-secondary !important;
  border-color: $color-my-secondary !important;
}

.dropdown {
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: flex;
    flex-direction: column;
  }
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

</style>
